/* You can add global styles to this file, and also import other style files */
// Option B: Include parts of Bootstrap
// Required
@use '@angular/material' as mat;
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
// Optional
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/utilities/spacing";
@import "bootstrap/scss/utilities/display";
@import "bootstrap/scss/utilities/text";
@import "@avtest/atlas-commons/css/roboto-font.css";
@import "material-icons/iconfont/material-icons.css";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@angular/material/theming";
@import "@avtest/atlas-commons/atlas-ds-colors";
@import "@avtest/atlas-commons/atlas-ds-utils";
@import "@avtest/atlas-material/scss/avtest-app-theme";
@import "@avtest/atlas-material/scss/atlas-table";
@import "@avtest/commons-angular/styles/variables";
@import "@avtest/commons-angular/styles/empty-state";
@import "@avtest/commons/styles/avtest-typography";

$chart-menu-light-color: mat.get-color-from-palette($light-accent);
$chart-menu-dark-color: mat.get-color-from-palette($dark-accent);
//@import "~@avtest/atlas-charts/atlas_ds_theme";


/**
FLAG ICONS
 */
$flag-icon-css-path: '~flag-icon-css/flags' !default;
@import "flag-icon-css/sass/variables";
@import "flag-icon-css/sass/flag-icons-base";
@import "flag-icon-css/sass/flag-icons-list";



html {
  height: 100%;
}

avtest-atlas-sidebar-container {
  --sidenav-width-open: 260px!important;
}

body {
  height: 100%;
}

.spacer {
  flex: 1 1 auto;
}

mat-toolbar {
  position: relative;
  z-index: 2;
}

button:focus {
  outline: initial;
}

.chart {
  height: 100%;
}


.clear-user-agent-styles table,
.clear-user-agent-styles thead,
.clear-user-agent-styles tbody,
.clear-user-agent-styles tfoot,
.clear-user-agent-styles tr,
.clear-user-agent-styles th,
.clear-user-agent-styles td {
  background: initial !important;
}

// other card stuff

atlas-card.no-card {
  box-shadow: none !important;
}

// dashboard addable component
ul.dashboard-addable-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

ul.dashboard-addable-menu li {
  display: inline;
  padding: 5px;
  cursor: pointer;
}

ul.dashboard-addable-menu li:hover {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.04);
}

// mat-list-item customisation
mat-list-item.auto-height {
  display: flex !important;
  flex-direction: column;
  height: auto !important;
  min-height: 48px;

  .mat-list-item-content {
    flex-grow: 1;
  }
}

// smaller toggle-button
.mat-shadow.mat-button-toggle-group {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-small .mat-button-toggle-label-content {
  line-height: 32px !important;
  padding: 0 4px !important;
}

// no script
.no-script-banner {
  z-index: 100;
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  background: #edb9be;
  border: 1px solid #db6069;
  padding: 16px;
}

.avtest-light .mat-simple-snackbar-action {
  color: $color-yellow;
}

.shared-selected-card {
  outline: 2px solid $color-blue !important;
  box-shadow: 0 9px 11px -5px rgba(14, 87, 122, 0.2), 0px 18px 28px 2px rgba(14, 87, 122, 0.14), 0px 7px 34px 6px rgba(14, 87, 122, 0.12) !important;
}

.avtest-dark .shared-selected-card {
  outline: 2px solid $color-yellow !important;
  box-shadow: 0 9px 11px -5px rgba(221, 201, 0, 0.2), 0px 18px 28px 2px rgba(221, 201, 0, 0.14), 0px 7px 34px 6px rgba(221, 201, 0, 0.12) !important;
}

// dashboard tour

.joyride-backdrop {
  background-color: rgba(0, 0, 0, .32) !important;
}

.joyride-step__container {
  padding: 24px !important;
  border-radius: 4px !important;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12) !important;
}

.joyride-step__header {
  padding: 0 !important;
  margin-bottom: 20px;
}

.joyride-step__body {
  max-width: 350px;
  padding: 0 !important;
}

.joyride-step__footer {
  align-items: flex-end !important;
  padding-left: 0 !important;
  padding-top: 8px;
}

.joyride-step__close {
  top: 12px !important;
  right: 12px !important;

  svg {
    vertical-align: top !important;
  }
}

.avtest-dark {
  .joyride-step__container {
    color: $color-white;
    background-color: #424242;
  }

  .joyride-step__title {
    color: mat.get-color-from-palette($dark-primary) !important;
  }

  .joyride-step__close svg line {
    stroke: #ffffff !important;
  }

  .joyride-arrow__top {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #424242;
  }

  .joyride-arrow__right {
    border-left: 11px solid #424242;
    border-bottom: 11px solid transparent;
    border-top: 11px solid transparent;
  }

  .joyride-arrow__bottom {
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 11px solid #424242;
  }

  .joyride-arrow__left {
    border-right: 11px solid #424242;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
  }
}

.material-icons.v-sub {
  vertical-align: sub;
}

.avtest-dark pre {
  color: $color-white;
}

.no-dialog-padding mat-dialog-container {
  padding: 0 !important;
}

.clickable {
  cursor: pointer;
}

// Fontawesome Icons are more
mat-nav-list i.mat-list-icon.fas {
  padding: 2px!important;
}
mat-nav-list i.mat-list-icon.fa-clipboard-list {
  margin-left: 3px!important;
}

// Fix for safari
div.flex-fill > div[dir="ltr"] + div {
  position: initial!important;
}

.atlas-card-content {
  overflow: initial!important;
}

:-moz-focusring {
  outline: none;
}

button.increased-padding .mat-list-item-content {
  padding: 0 calc(16px + .5rem)!important;
}

.h-100 {
  height: 100%!important;
}

.action-buttons {
  z-index: 1111;
  position: sticky;
  bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;

  .introduction-buttons-inner {
    padding: .25rem 1rem .25rem .25rem;
    //margin-right: -.5rem;
    background-color: #ffffff;
    border-radius: 500px 0 0 500px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    //width: calc(1.75rem + 80px);
    transition: width 200ms ease-in-out;
    overflow: hidden;
    white-space: nowrap;

    button {
      margin-right: 1rem;
      box-shadow: none;
    }

    button:last-child {
      margin-right: 0;
    }
  }
}

.echarts-menu {
  position: absolute;
  right: 1rem;
  bottom: .85rem;
}
